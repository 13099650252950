/** @format */

import useSecureQuery from '@common/application/auth/useSecureQuery';
import useInfiniteScrollSearch from '@common/application/hooks/useTagSearch/useInfiniteScrollSearch';
import { AccountClient, DocumentClient } from '@common/model/apiClient';
import { Tag } from '@common/model/apiClient/Tag';
import { User } from '@common/model/apiClient/User';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { useSecureSession } from '@heimdall/react';
import { BaseRealmRole, BaseRole } from '@heimdall/types';
import DashboardDocListItem from '@organisms/Dashboard/DashboardDocListItem';
import QuickNav from '@organisms/Dashboard/QuickNav';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from 'react-query';

interface DocumentQuickNavProps {}

function DocumentQuickNav(props: DocumentQuickNavProps) {
  const session = useSecureSession<BaseRealmRole, BaseRole>();
  const [documentViewHistory, setDocumentViewHistory] = useState();
  const [documentCreateHistory, setDocumentCreateHistory] = useState<Array<DocumentClient>>();
  const [documentTags, setDocumentTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [taggedDocs, setTaggedDocs] = useState<Array<DocumentClient>>([]);

  const { search, setSearch, fetchNext, updateResults, hasMore, searchAfter, refetch } = useInfiniteScrollSearch([documentTags, setDocumentTags], {
    size: 10,
    queryKey: 'document_tag_query',
  });

  useSecureQuery(['document_tag_query', search, searchAfter], () => Tag.list('document', search, {}, 10, searchAfter), {
    onSuccess: (data) => {
      updateResults(data.payload);
    },
  });

  // const { data, isFetching: isTaggedDocsFetching } = useSecureQuery(
  //   ['tagged_docs_search', selectedTags],
  //   () => DocumentClient.list('', {}, { deleted: false, musts: selectedTags.map((t) => ({ terms: { tags: [`${t.id}`] } })) }),
  //   // () => AccountClient.list('', {}, { deleted: false, musts: accountTagFilters.map((t) => ({ terms: { tags: `${t.id}` } })) }, 10),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchInterval: false,
  //     keepPreviousData: true,
  //     enabled: !!selectedTags.length,
  //     onSuccess: (data) => {
  //       // setTaggedDocs([]);
  //       setTaggedDocs(data.payload);
  //     },
  //   },
  // );

  const { data, isFetching: isTaggedDocsFetching } = useSecureQuery(['tagged_docs_search', selectedTags], () => DocumentClient.getByTags(selectedTags.map((t) => t.id)), {
    refetchOnWindowFocus: false,
    refetchInterval: false,
    keepPreviousData: true,
    enabled: !!selectedTags.length,
    onSuccess: (data) => {
      // setTaggedDocs([]);
      setTaggedDocs(data.payload);
    },
  });

  const { isFetching: historyFetching } = useQuery([session?.data?.user?.id, 'user_history'], () => User.getHistory(session?.data?.user?.id), {
    onSuccess: (data) => {
      const { payload } = data.data;
      setDocumentViewHistory(
        payload.documents.map((doc) => {
          doc.accountName = doc.account.name;
          delete doc.account;
          return doc;
        }),
      );
    },
  });
  const { isFetching: recentDocsFetching } = useQuery([session?.data?.user?.id, 'user_recent_docs'], () => User.getRecentDocuments(session?.data?.user?.id), {
    onSuccess: (data) => {
      const { payload } = data.data;
      setDocumentCreateHistory(payload);
      // setDocumentHistory(
      //   payload.documents.map((doc) => {
      //     doc.accountName = doc.account.name;
      //     delete doc.account;
      //     return doc;
      //   }),
      // );
    },
  });

  const documentCategories = useMemo(
    () => [
      {
        icon: 'clock-rotate-left' as IconName,
        label: <FormattedMessage defaultMessage={'Recently Created Documents'} />,
        records: documentCreateHistory,
        id: 'recent',
        render: (r) => <DashboardDocListItem doc={r as DocumentClient} />,
        loading: recentDocsFetching,
        className: 'recent-created-documents',
      },
      {
        icon: 'eye' as IconName,
        label: <FormattedMessage defaultMessage={'Recently Viewed Documents'} />,
        records: documentViewHistory,
        id: 'history',
        render: (r) => <DashboardDocListItem doc={r as DocumentClient} />,
        loading: historyFetching,
        className: 'recent-viewed-documents',
      },
      {
        icon: 'tag' as IconName,
        label: <FormattedMessage defaultMessage={'Tagged Documents'} description={'Documents filtered by category tags'} />,
        // records: documentHistory,
        records: !!selectedTags.length ? taggedDocs : [],
        id: 'tag',
        render: (r) => <DashboardDocListItem doc={r} />,
        loading: isTaggedDocsFetching,
        className: 'tagged-documents',
      },
    ],
    [documentCreateHistory, documentViewHistory, historyFetching, isTaggedDocsFetching, recentDocsFetching, selectedTags.length, taggedDocs],
  );

  const tagCategories = [
    {
      label: <FormattedMessage defaultMessage={'Documents'} description={'Help documents header'} />,
      allTagsFetched: !hasMore,
      setSelectedTags: setSelectedTags,
      selectedTags: selectedTags,
      fetchNextTags: fetchNext,
      setTagSearch: setSearch,
      tagOptions: documentTags,
      tagSearch: search,
      id: 'documentQuickNavDocumentTags',
    },
  ];

  return <QuickNav tagCategories={tagCategories} categories={documentCategories} />;
}

export default DocumentQuickNav;
